import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Material
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';

// Icons
import { MatIconModule } from '@angular/material/icon';

@NgModule({
    declarations: [],
    imports: [CommonModule, MatSelectModule, MatInputModule, MatButtonModule, MatIconModule],
    exports: [MatSelectModule, MatInputModule, MatButtonModule, MatIconModule],
})
export class SharedMaterialModule {}
