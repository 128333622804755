import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';

/**
 * Service for managing asset paths.
 */
@Injectable({
    providedIn: 'root',
})
export class AssetsService {
    assetPath: string;

    /**
     * Creates an instance of AssetsService.
     * Determines the asset path based on the environment configuration.
     */
    constructor() {
        // Set the assetPath based on the value of environment.oneFolder
        this.assetPath = environment.oneFolder ? '' : 'assets/';
    }

    /**
     * Gets the asset path.
     * @returns The asset path as a string.
     */
    getAssetPath(): string {
        return this.assetPath;
    }
}
