<div class="card" *ngIf="isProductDefined()">
    <div class="card-header">
        <a [href]="product?.url || ''" target="_blank"
            ><img src="{{ assetPathToProducts || '' }}{{ product?.imageUrl }}" alt="{{ product?.name }}"
        /></a>
    </div>
    <div class="card-body">
        <div class="productname">
            <div class="name">
                <span class="icon"> <mat-icon>star</mat-icon></span
                >{{ product?.name }}
            </div>
            <div class="button">
                <a mat-flat-button color="primary" [href]="product?.url || ''" target="_blank">more</a>
            </div>
        </div>

        <div class="more" (click)="toggleInfo(cardId)">
            <mat-icon class="moreicon">{{ isOpen(cardId) ? 'expand_less' : 'expand_more' }}</mat-icon>
            <div class="morelink">{{ isOpen(cardId) ? 'Show less...' : 'Show more...' }}</div>
        </div>

        <div class="padding-container">
            <div
                class="description"
                [class.expanded]="isOpen(cardId)"
                [class.hovered]="isHovered"
                (mouseenter)="onMouseEnter()"
                (mouseleave)="onMouseLeave()"
                (click)="toggleInfo(cardId)"
                [innerHTML]="product?.description || ''"></div>
        </div>
    </div>
</div>
