import { Injectable } from '@angular/core';
import { LoggerService } from '../../logger.service';

@Injectable({
    providedIn: 'root',
})
/**
 * Service for managing the state of expanded cards.
 */
export class CardService {
    constructor(private loggerService: LoggerService) {}

    private expandedCardId: string = '';

    /**
     * Retrieves the ID of the currently expanded card.
     * @returns The ID of the expanded card, or '' if no card is expanded.
     */
    getExpandedCardId(): string {
        this.loggerService.trace('getExpandedCardId');

        return this.expandedCardId;
    }

    /**
     * Sets the ID of the currently expanded card.
     * @param cardId - The ID of the card to be expanded, or '' to collapse all cards.
     */
    setExpandedCardId(cardId: string): void {
        this.loggerService.trace('setExpandedCardId');

        this.expandedCardId = cardId;
    }
}
