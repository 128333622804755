import { Component, OnInit } from '@angular/core';

import { LoggerService } from '../../logger.service';
import { Product } from '../product.interface';
import { ProductResolver } from './product.resolver';
import { Group } from '../group.interface';

@Component({
    selector: 'app-product-grid',
    templateUrl: './product-grid.component.html',
    styleUrls: ['./product-grid.component.scss'],
})
export class ProductGridComponent implements OnInit {
    groups: Group[] = [];

    constructor(private loggerService: LoggerService, private productResolver: ProductResolver) {}

    /**
     * Initializes the component and loads the necessary data.
     *
     * @return {void}
     */
    ngOnInit(): void {
        this.loggerService.trace('ngOnInit');

        this.productResolver.resolve().subscribe(
            jsonData => {
                this.loggerService.trace('config loaded', JSON.stringify(jsonData));

                /**
                 * Checks if a group is valid.
                 *
                 * @param {any} group - The group to be checked.
                 * @param {number} index - The index of the group in the array.
                 * @return {boolean} Returns true if the group is valid, false otherwise.
                 */
                const isValidGroup = (group: any, index: number): group is Group => {
                    if (group.name !== undefined && Array.isArray(group.products) && group.products.length > 0) {
                        return true;
                    } else {
                        // group data is invalid, give debug information
                        // Gruppendaten sind ungültig, gib Debug-Informationen aus
                        if (group.name === undefined) {
                            this.loggerService.error(`Invalid group data at index ${index}: Group name is missing.`);
                        } else if (!Array.isArray(group.products)) {
                            this.loggerService.error(
                                `Invalid group data at index ${index}: Products should be an array.`,
                            );
                        } else if (group.products.length === 0) {
                            this.loggerService.error(
                                `Invalid group data at index ${index}: No products found in group.`,
                            );
                        } else {
                            this.loggerService.error(`Invalid group data at index ${index}:`, JSON.stringify(group));
                        }

                        return false;
                    }
                };

                /**
                 * Checks if the given product is a valid instance of the Product class.
                 *
                 * @param {any} product - The product to be checked.
                 * @return {boolean} - Returns true if the product is valid, false otherwise.
                 */
                const isValidProduct = (product: any, index: number): product is Product => {
                    if (
                        product.name !== undefined &&
                        product.description !== undefined &&
                        product.imageUrl !== undefined &&
                        (product.url === undefined || typeof product.url === 'string')
                    ) {
                        return true;
                    } else {
                        // product data is invalid, give debug information
                        if (product.name === undefined) {
                            this.loggerService.error(
                                `Invalid product data at index ${index}: Product name is missing.`,
                            );
                        } else if (product.description === undefined) {
                            this.loggerService.error(
                                `Invalid product data at index ${index}: Product description is missing.`,
                            );
                        } else if (product.imageUrl === undefined) {
                            this.loggerService.error(
                                `Invalid product data at index ${index}: Product imageUrl is missing.`,
                            );
                        } else if (product.url !== undefined && typeof product.url !== 'string') {
                            this.loggerService.error(
                                `Invalid product data at index ${index}: Product url should be a string.`,
                            );
                        } else {
                            this.loggerService.error(
                                `Invalid product data at index ${index}:`,
                                JSON.stringify(product),
                            );
                        }
                        return false;
                    }
                };

                if (jsonData.groups && jsonData.groups.length > 0) {
                    const validGroups = jsonData.groups.every((group: any, index: number) =>
                        isValidGroup(group, index),
                    );

                    if (validGroups) {
                        const validProducts = jsonData.groups.every((group: any) => {
                            return group.products.every((product: any, index: number) =>
                                isValidProduct(product, index),
                            );
                        });

                        if (validProducts) {
                            // groups and products are valid
                            this.groups = jsonData.groups;
                        } else {
                            this.loggerService.error('Invalid product data structure.');
                        }
                    } else {
                        this.loggerService.error('Invalid group data structure.');
                    }
                } else {
                    this.loggerService.error('No groups found in config file.');
                }
            },
            error => {
                this.loggerService.error(JSON.stringify(error));
            },
        );
    }
}
