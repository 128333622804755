import { NgxLoggerLevel } from 'ngx-logger';
import packageJson from '../../package.json';

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    oneFolder: true,
    version: packageJson.version,
    logger: {
        level: NgxLoggerLevel.DEBUG,
        disableConsoleLogging: false,
        serverLogLevel: NgxLoggerLevel.OFF,
        enableSourceMaps: false, // if true, the logger-messages are async
        disableFileDetails: false,
        timestampFormat: 'HH:mm:ss.SSS',
        serverCallsOutsideNgZone: false,
    },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
