import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { NGXLogger, NgxLoggerLevel } from 'ngx-logger';
//import { NGXLogger } from 'ngx-logger';

@Injectable({
    providedIn: 'root',
})
export class LoggerService {
    constructor(private logger: NGXLogger) {
        this.configureLogger();
    }

    private configureLogger(): void {
        // Überprüfen des Production Mode
        if (environment.production) {
            // Wenn die Anwendung im Produktionsmodus läuft, Loglevel auf ERROR setzen
            this.logger.updateConfig({
                level: environment.logger.level,
                disableConsoleLogging: environment.logger.disableConsoleLogging,
                serverLogLevel: environment.logger.serverLogLevel,
                enableSourceMaps: environment.logger.enableSourceMaps,
                serverCallsOutsideNgZone: false,
            });
        } else {
            // Wenn die Anwendung nicht im Produktionsmodus läuft, Loglevel auf TRACE setzen
            this.logger.updateConfig({
                level: environment.logger.level,
                disableConsoleLogging: environment.logger.disableConsoleLogging,
                serverLogLevel: environment.logger.serverLogLevel,
                enableSourceMaps: environment.logger.enableSourceMaps,
                disableFileDetails: false,
                timestampFormat: ' ', // HH:mm:ss
            });
        }
    }

    /**
     * Retrieves the filename and line number of the caller.
     * @returns {Object} An object containing the filename and line number.
     * @property {string} fileName - The name of the file where the caller is located.
     * @property {string} lineNumber - The line number in the file where the caller is located.
     */
    private getCallerInfo(): { fileName: string; lineNumber: string } {
        const stackTrace = new Error().stack?.split('\n');
        let callerLine = 'Unknown line';
        let callerFile = 'Unknown file';
        let callerLineNumber = 'Unknown line';

        if (stackTrace) {
            // name of the current function
            const functionName = this.getCallerInfo.name;

            // name of the current service
            const serviceName = Object.getPrototypeOf(this).constructor.name;

            // caller line in the stack trace that is outside of the service and function names
            const callerLine = stackTrace.slice(1).find(stackLine => {
                return !stackLine.includes(serviceName) && !stackLine.includes(functionName);
            });

            // filename and line number from the call
            if (callerLine) {
                callerFile = callerLine.match(/\((.*):\d+:\d+\)/)?.[1] || 'Unknown file';
                callerLineNumber = callerLine.match(/:(\d+):\d+/)?.[1] || 'Unknown line';
            }
        }

        return {
            fileName: callerFile,
            lineNumber: callerLineNumber,
        };
    }

    /**
     * Generates the log message by combining the caller info and the provided arguments.
     * @param args - The log message arguments.
     * @returns The generated log message.
     */
    private generateLogMessage(args: any[]): string {
        const { fileName, lineNumber } = this.getCallerInfo();
        return `[${fileName}:${lineNumber}] - ${args.join(' ')}`;
    }

    /**
     * Logs a trace message.
     * @param args - The trace message arguments.
     */
    trace(...args: any[]): void {
        const message = this.generateLogMessage(args);
        this.logger.trace(message);
    }

    log(...args: any[]): void {
        const message = this.generateLogMessage(args);
        this.logger.log(message);
    }

    debug(...args: any[]): void {
        const message = this.generateLogMessage(args);
        this.logger.debug(message);
    }

    info(...args: any[]): void {
        const message = this.generateLogMessage(args);
        this.logger.info(message);
    }

    warn(...args: any[]): void {
        const message = this.generateLogMessage(args);
        this.logger.warn(message);
    }

    error(...args: any[]): void {
        const message = this.generateLogMessage(args);
        this.logger.error(message);
    }

    fatal(...args: any[]): void {
        const message = this.generateLogMessage(args);
        this.logger.fatal(message);
    }
}
