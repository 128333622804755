<ng-container *ngFor="let group of groups; let groupIndex = index; let isFirst = first">
    <ng-container *ngIf="group.products.length > 0">
        <div class="productheading" [ngClass]="{ firstGroup: isFirst }" *ngIf="group?.name && group.name.length > 0">
            {{ group.name }}
        </div>
        <div class="grid-container">
            <div class="grid-container-card" *ngFor="let product of group.products; let cardId = index">
                <app-product-card
                    [product]="product"
                    [cardId]="groupIndex.toString() + '-' + cardId.toString()"></app-product-card>
            </div>

            <!--    these cards are only to ensure left alignment with flex layout -->
            <div class="grid-container-card ghost-card"></div>
            <div class="grid-container-card ghost-card"></div>
            <div class="grid-container-card ghost-card"></div>
            <div class="grid-container-card ghost-card"></div>
            <div class="grid-container-card ghost-card"></div>
            <div class="grid-container-card ghost-card"></div>
        </div>
    </ng-container>
</ng-container>
