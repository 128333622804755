<header class="Version {{ environment.version }}">
    <div class="container">
        <div class="logo">
            <img src="{{ assetPath }}mpmx-cockpit-logo.png" alt="mpmX Cockpit Logo" (click)="closeAllCards()" />
            {{ selectedLng }}
        </div>
        <div class="language" *ngIf="selectedLng && selectedLng.length > 0">
            <span class="icon"> <mat-icon>language</mat-icon></span>
            <mat-form-field>
                <mat-select [value]="selectedLng" (selectionChange)="onLanguageChange($event)">
                    <mat-option
                        *ngFor="let lng of languages[selectedLng]"
                        [value]="lng.value"
                        [class.languageActive]="selectedLng === lng.value"
                        [class.mat-accent]="selectedLng === lng.value"
                        [ngStyle]="{ color: selectedLng === lng.value ? '#ff0090' : '#f2f2f2' }">
                        {{ lng.label }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
</header>
<div class="content" #content>
    <app-product-grid></app-product-grid>
</div>
