import { Component, ElementRef, HostBinding, ViewChild } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { AssetsService } from './assets.service';

import { environment } from '../environments/environment';
import { LoggerService } from './logger.service';
import { CardService } from './product/product-grid/card.service';

interface Language {
    label: string;
    value: string;
}

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent {
    @HostBinding('class.masterlayout') myClass = true;
    @ViewChild('content') gridContainer!: ElementRef;

    title = 'mpmX-Cockpit';
    environment = environment;
    assetPath: string;

    languages: { [key: string]: Language[] } = {
        de: [
            { label: 'Deutsch', value: 'de' },
            { label: 'Englisch', value: 'en' },
        ],
        en: [
            { label: 'German', value: 'de' },
            { label: 'English', value: 'en' },
        ],
    };

    // todo: i18n einbauen
    // selectedLng = 'en';
    selectedLng = '';

    constructor(
        private loggerService: LoggerService,
        private assetsService: AssetsService,
        private cardService: CardService,
    ) {
        this.assetPath = this.assetsService.getAssetPath();

        /* just to test if logging works
        this.loggerService.trace('Trace message');
        this.loggerService.debug('Debug message');
        this.loggerService.info('Info message');
        this.loggerService.log('Default log message');
        this.loggerService.warn('Warning message');
        this.loggerService.error('Error message');
        this.loggerService.fatal('Fatal message');
        */
        // todo: i18n einbauen
        // this.selectedLng = this.getBrowserLanguage();
    }

    /**
     * Get the browser language code.
     * @returns {string} The language code based on the browser's settings or a fallback value of 'en' if not available.
     */
    private getBrowserLanguage(): string {
        this.loggerService.trace('getBrowserLanguage');

        // Get the browser's language using the navigator.language property or fallback to 'en-en'
        const browserLanguage = navigator.language ?? 'en-en';

        // Split the language code and country code, and extract the language code
        let [languageCode, _countryCode] = browserLanguage.split('-');
        if (!languageCode || languageCode.trim() === '') {
            // If the language code is not available or empty, fallback to 'en'
            languageCode = 'en';
        }

        this.loggerService.info('language found: ', languageCode);
        return languageCode;
    }

    /**
     * Get the list of configured language codes.
     * @returns {string[]} - The list of language codes.
     */
    private getLanguageCodes(): string[] {
        this.loggerService.trace('getLanguageCodes');

        // Extract all keys from the 'languages' data structure
        const languageKeys = Object.keys(this.languages);

        this.loggerService.info('language keys found: ', languageKeys);

        // return the extracted keys as an array of strings
        return languageKeys;
    }

    /**
     * Handles the language change event.
     * @param {MatSelectChange} language - The selected language change event.
     */
    onLanguageChange(language: MatSelectChange) {
        this.loggerService.trace('onLanguageChange: ', language.value);

        const languageCodes = this.getLanguageCodes();

        // Check if the selected language code is a valid key
        if (languageCodes.includes(language.value) && this.selectedLng?.length > 0) {
            this.selectedLng = language.value;
        } else {
            // Invalid language code - handle the error or display an error message
            this.loggerService.error('Invalid language code:', language.value);
        }
    }

    /**
     * Scrolls the content area to the top with a smooth animation.
     * @returns {void}
     */
    scrollToTop(): void {
        this.loggerService.trace('scrollToTop');

        if (this.gridContainer) {
            const container = this.gridContainer.nativeElement;
            container.scrollTo({ top: 0, behavior: 'smooth' });
        }
    }

    /**
     * Closes all expanded cards and scrolls the content area to the top.
     * @returns {void}
     */
    closeAllCards(): void {
        this.loggerService.trace('closeAllCards');

        this.cardService.setExpandedCardId('');
        this.scrollToTop();
    }
}
