import { Component, Input } from '@angular/core';
import { LoggerService } from '../../../logger.service';
import { Product } from '../../product.interface';
import { AssetsService } from '../../../assets.service';
import { CardService } from '../card.service';

@Component({
    selector: 'app-product-card',
    templateUrl: './product-card.component.html',
    styleUrls: ['./product-card.component.scss'],
})
/**
 * Component for displaying a product card.
 */
export class ProductCardComponent {
    @Input() product: Product = {
        name: '',
        description: '',
        imageUrl: '',
    };
    @Input() cardId: string = '';

    isOpenDescription = false;
    isHovered = false; //flag to handle hover to change scrollbar properties
    assetPathToProducts: string;

    constructor(
        private loggerService: LoggerService,
        private cardService: CardService,
        private assetsService: AssetsService,
    ) {
        // Get the asset path from the AssetsService, fallback to an empty string if null or undefined
        this.assetPathToProducts = this.assetsService.getAssetPath() ?? '';

        // Check if the assetPathToProducts has a length greater than 0
        if (this.assetPathToProducts.length > 0) {
            // Append 'products/' to the assetPathToProducts
            this.assetPathToProducts += 'products/';
        }
    }

    /**
     * Checks if the product is defined.
     * @returns True if the product is defined, false otherwise.
     */
    isProductDefined(): boolean {
        this.loggerService.trace('isProductDefined');
        return this.product !== undefined && this.product.name?.length > 0;
    }

    /**
     * Toggles the visibility of the product information.
     * @param cardId - The ID of the card.
     */
    toggleInfo(cardId: string): void {
        this.loggerService.trace('toggleInfo', cardId);

        if (this.isOpen(cardId)) {
            this.cardService.setExpandedCardId('');
        } else {
            this.cardService.setExpandedCardId(cardId);
        }
    }

    /**
     * Checks if the card with the specified ID is currently open.
     * @param cardId - The ID of the card.
     * @returns True if the card is open, false otherwise.
     */
    isOpen(cardId: string): boolean {
        return this.cardService.getExpandedCardId() === cardId;
    }

    /**
     * Handles the mouse enter event.
     */
    onMouseEnter(): void {
        this.loggerService.trace('onMouseEnter');
        this.isHovered = true;
    }

    /**
     * Handles the mouse leave event.
     */
    onMouseLeave(): void {
        this.loggerService.trace('onMouseLeave');
        this.isHovered = false;
    }
}
