import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { AssetsService } from '../../assets.service';

/**
 * Resolver service for product resolution.
 * Loads the product information at start
 */
@Injectable({ providedIn: 'root' })
export class ProductResolver implements Resolve<any> {
    assetsPath: string;

    /**
     * Creates an instance of ProductResolver.
     * @param http The HttpClient for HTTP requests.
     * @param assetsService The AssetsService for accessing asset paths.
     */
    constructor(private http: HttpClient, private assetsService: AssetsService) {
        this.assetsPath = assetsService.getAssetPath();
    }

    /**
     * Resolves the product information.
     * @returns An Observable that contains the product information.
     */
    resolve(): Observable<any> {
        return this.http.get(this.assetsPath + 'products.json');
    }
}
