import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';

import { environment } from 'src/environments/environment';
const environmentConfig: any = environment.logger;

import { LoggerModule, NgxLoggerLevel, TOKEN_LOGGER_WRITER_SERVICE } from 'ngx-logger';
import { LoggerService } from './logger.service';

// Material
import { SharedMaterialModule } from './shared/modules/shared-material/shared-material.module';

import { BrowserModule } from '@angular/platform-browser';

// load products dynamic
import { ProductResolver } from './product/product-grid/product.resolver';

import { AppComponent } from './app.component';
import { ProductGridComponent } from './product/product-grid/product-grid.component';
import { ProductCardComponent } from './product/product-grid/product-card/product-card.component';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatIconModule } from '@angular/material/icon';

// ngx-logger: overwrite console file links for better debugging
import { Injectable } from '@angular/core';
import { INGXLoggerConfig, INGXLoggerMetadata, NGXLoggerWriterService } from 'ngx-logger';
@Injectable({
    providedIn: 'root',
})
export class MyLoggerWriterService extends NGXLoggerWriterService {
    protected override prepareMetaString(metadata: INGXLoggerMetadata, config: INGXLoggerConfig): string {
        const fileName =
            metadata.fileName?.charAt(0) === '.' ? 'webpack://' + metadata.fileName?.substring(1) : metadata.fileName;
        return super.prepareMetaString({ ...metadata, fileName }, config);
    }
}

@NgModule({
    declarations: [AppComponent, ProductGridComponent, ProductCardComponent],
    imports: [
        LoggerModule.forRoot(environmentConfig, {
            writerProvider: { provide: TOKEN_LOGGER_WRITER_SERVICE, useClass: MyLoggerWriterService },
        }),
        CommonModule,
        SharedMaterialModule,
        BrowserModule,
        HttpClientModule,
        BrowserAnimationsModule,
        MatIconModule,
    ],
    exports: [SharedMaterialModule],
    providers: [LoggerService, DatePipe, ProductResolver],
    bootstrap: [AppComponent],
})
export class AppModule {}
